import React from 'react';
import locales from 'locales';
import { Button } from 'antd';
import { Popconfirm } from 'antd';
import { useStoreState } from 'easy-peasy';
import { deleteResource } from 'services/resource';

//
const specialResource = ['blogs'];

export default function DeleteButton(props) {
  const { resourceModel } = useStoreState((state) => state.resource);
  const { id, onDone = () => {}, allowDelete = true } = props;
  const onConfirm = async () => {
    const resp = await deleteResource({ resourceModel, id });

    if (resp.success) {
      onDone();
    } else {
    }
  };

  return (
    <Popconfirm
      placement="topLeft"
      title={locales.removeConfirmMessage}
      onConfirm={onConfirm}
      okText={locales.yes}
      cancelText={locales.no}
      disabled={specialResource.includes(resourceModel) && allowDelete}>
      <Button
        type="danger"
        disabled={specialResource.includes(resourceModel) && allowDelete}>
        {locales.web_actions.delete}
      </Button>
    </Popconfirm>
  );
}
