import { thunk, action, thunkOn } from 'easy-peasy';
import {
  applyAllUserRatio,
  listResource,
  refreshMerchant,
} from 'services/resource';

const initialState = {
  resourceName: '',
  basePath: '',
  resourceModel: '',
  listing: {
    pagination: { current: 1, pageSize: 10, total: null },
    records: [],
    ratio_user: null,
  },
  form: {},
  isLoading: true,
  errors: {},
  params: {
    page: 1,
    per_page: 10,
  },
};

const resourceModel = {
  ...initialState,

  setResource: action((state, payload) => {
    state.resourceName = payload.resourceName;
    state.resourceModel = payload.resourceModel;
    state.basePath = payload.basePath;
    state.listing = {
      pagination: { current: 1, pageSize: 2 },
      records: [],
    };
    state.form = {};
    state.isLoading = true;
    state.errors = {};
  }),

  setListing: action((state, payload) => {
    state.listing = payload;
    state.isLoading = false;
  }),

  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),

  setErrors: action((state, payload) => {
    state.errors = payload;
    state.isLoading = false;
  }),

  changeParams: action((state, { params }) => {
    const formattedParams = {
      ...(params.keyword && {
        keyword:
          typeof params.keyword === 'string'
            ? params.keyword.toLowerCase()
            : params.keyword,
      }),
      ...(params.category && { category: params.category }),
      ...(params.types && { types: params.types }),
      ...(params.providers && { providers: params.providers }),
      ...(params.order && { order: params.order, order_by: params.order_by }),
      page: params.page,
      per_page: params.per_page,
    };

    state.params = formattedParams;
  }),

  resetParams: action((state, payload) => {
    state.params = initialState.params;
  }),

  searchParams: action((state, keyword) => {
    state.params = {
      ...initialState.params,
      keyword: typeof keyword === 'string' ? keyword.toLowerCase() : keyword,
    };
  }),

  loadDataListener: thunkOn(
    (actions) => [
      actions.changeParams,
      actions.resetParams,
      actions.searchParams,
    ],
    async (actions, target, { getState }) => {
      const { resourceModel, isLoading, params } = getState();
      if (!isLoading) actions.setLoading(true);

      const resp = await listResource({ resourceModel, ...params });

      if (resp.success && getState().resourceModel === resourceModel) {
        actions.setListing({
          pagination: {
            current: resp.data.pagination?.page,
            pageSize: resp.data.pagination?.per_page,
            total: resp.data.pagination?.total_count,
          },
          records: resp.data.records,
          ratio_user: resp.data.ratio_user,
        });
      } else {
        actions.setErrors(resp.errors);
      }
    },
  ),

  refreshMerchant: thunk(async (actions, payload, { getState }) => {
    const { isLoading } = getState();
    if (!isLoading) actions.setLoading(true);
    const { success } = await refreshMerchant();
    if (success) {
      await actions.resetParams();
    }
    actions.setLoading(false);
  }),

  setAllUserRatio: thunk(async (actions, ratio, { getState }) => {
    const { isLoading } = getState();
    if (!isLoading) actions.setLoading(true);

    const { success } = await applyAllUserRatio({
      ratio_user: parseInt(ratio),
    });

    if (success) {
      await actions.resetParams();
    }
    actions.setLoading(false);
  }),
};

export default resourceModel;
