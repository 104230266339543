import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Space,
  Skeleton,
  InputNumber,
  Select,
  Input,
  Alert,
} from 'antd';
import locales from 'locales';
import { useHistory } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import _get from 'lodash/get';

import {
  createResource,
  updateResource,
  showResource,
  getCashRewardCategories,
} from 'services/resource';

import PhotoPicker from 'components/PhotoPicker';
import BannerPicker from 'components/BannerPicker';
import SwitchInput from 'components/inputs/SwitchInput';
import { MERCHANT_TYPES, PROVIDER_TYPES } from 'utils/affiliate.util';
import { convertFields } from 'utils/common.util';

const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default function PostForm(props) {
  const { basePath, resourceModel } = useStoreState((state) => state.resource);

  const { id } = props;
  const isNewForm = !id;
  const [formRef] = Form.useForm();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState({ isIniting: true, category: [] });
  const [file, setFile] = useState({ logo: null, banner: null });

  useEffect(() => {
    async function initForm() {
      if (!state.isIniting) setState((prev) => ({ ...prev, isIniting: true }));

      const response = await getCashRewardCategories();
      const categories = _get(response, 'data', []);

      const category = categories.map((category) => ({
        key: category,
        value: category,
      }));

      if (isNewForm) {
        setState((prev) => ({ ...prev, isIniting: false, category }));
        return;
      }

      const resp = await showResource({ resourceModel, id });

      if (resp.success) {
        const entry = resp.data.record;
        const converted = convertFields(entry);

        formRef.setFieldsValue({ ...converted });
      } else {
        console.log(resp.errors);
      }

      setState((prev) => ({ ...prev, isIniting: false, category }));
    }

    if (resourceModel) initForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceModel, id]);

  // const transformTypes = (types) => (types || []).map((type) => ({ type }));

  const onFinish = React.useCallback(
    async (formData) => {
      setLoading(true);
      const { affiliate_types, avatar, banner, ...others } = formData;
      const types = affiliate_types.join(',');

      const avatarFile = file.logo ? { avatar: file.logo } : {};
      const bannerFile = file.banner ? { banner: file.banner } : {};

      const converted = convertFields(others);

      const submitData = {
        resourceModel,
        ...converted,
        ...avatarFile,
        ...bannerFile,
        affiliate_types: types,
        cashback: others.cashback.toString(),
      };

      const resp = isNewForm
        ? await createResource(submitData)
        : await updateResource({ ...submitData, id });
      setLoading(false);
      if (resp.success) {
        history.replace(basePath);
      }
    },
    [history, isNewForm, file, basePath, resourceModel, id],
  );

  const onCancel = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  if (state.isIniting) {
    return (
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
      </Space>
    );
  }

  return (
    <>
      <Form
        {...layout}
        form={formRef}
        onFinish={onFinish}
        initialValues={{ active: false, up_to: false, instant: false }}>
        <Form.Item label="Active" name="active">
          <SwitchInput
            onChange={(value) => {
              formRef.setFieldsValue(value);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Logo"
          name="avatar"
          rules={[
            {
              required: isNewForm,
              message: 'Logo is required',
            },
          ]}>
          <PhotoPicker
            name="avatar"
            url={formRef.getFieldValue('avatar_url')}
            onFileAdded={(file) => {
              setFile((prev) => ({ ...prev, logo: file }));
              formRef.setFieldsValue({
                avatar: file,
              });
            }}
          />
        </Form.Item>

        <Form.Item
          label="Banner"
          name="banner"
          rules={[
            {
              required: isNewForm,
              message: 'Banner is required',
            },
          ]}>
          <BannerPicker
            name="banner"
            url={formRef.getFieldValue('banner_url')}
            onFileAdded={(file) => {
              setFile((prev) => ({ ...prev, banner: file }));
              formRef.setFieldsValue({
                banner: file,
              });
            }}
          />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Name is required',
            },
            {
              max: 100,
              message: 'Name cannot exceed 100 characters',
            },
          ]}>
          <Input placeholder="Enter cash reward title" />
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              max: 100,
              message: 'Title cannot exceed 100 characters',
            },
          ]}>
          <Input placeholder="Enter cash reward title" />
        </Form.Item>

        <Form.Item
          label="Sub Title"
          name="sub_title"
          rules={[
            {
              max: 100,
              message: 'Sub title cannot exceed 100 characters',
            },
          ]}>
          <Input placeholder="Enter cash reward sub title" />
        </Form.Item>

        <Form.Item
          label="Tracking url"
          name="tracking_url"
          rules={[
            {
              required: true,
              message: 'Tracking url is required',
            },
          ]}>
          <Input placeholder="Tracking url" />
        </Form.Item>

        <Form.Item
          label="Merchant type"
          name="affiliate_types"
          rules={[
            {
              required: true,
              message: 'Merchant type is required',
            },
          ]}>
          <Select
            mode="multiple"
            name="affiliate_types"
            style={{ width: '100%' }}
            options={MERCHANT_TYPES}
            onChange={(value) => {
              formRef.setFieldsValue(value);
            }}
            placeholder={'Please select merchant type'}
          />
        </Form.Item>
        <Form.Item
          label="Provider"
          name="affiliate_provider"
          rules={[
            {
              required: true,
              message: 'Provider is required',
            },
          ]}>
          <Select
            name="affiliate_provider"
            style={{ width: '100%' }}
            options={PROVIDER_TYPES}
            onChange={(value) => {
              formRef.setFieldsValue(value);
            }}
            placeholder={'Please select provider'}
          />
        </Form.Item>

        <Form.Item
          label="Category"
          name="category"
          rules={[
            {
              required: true,
              message: 'Category is required',
            },
          ]}>
          <Select
            name="category"
            style={{ width: '100%' }}
            options={state.category}
            onChange={(value) => {
              formRef.setFieldsValue(value);
            }}
            placeholder={'Please select a category'}
          />
        </Form.Item>

        <Form.Item label="Up to" name="up_to">
          <SwitchInput
            onChange={(value) => {
              formRef.setFieldsValue(value);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Cashback (%)"
          name="cashback"
          rules={[{ required: true, message: 'Cashback is required' }]}>
          <InputNumber placeholder="Cashback" style={{ width: '50%' }} />
        </Form.Item>

        {!isNewForm && !!formRef.getFieldValue('original_commission_rate') && (
          <Form.Item label="Merchant Commission Rate">
            <Alert
              message={`${formRef.getFieldValue('original_commission_rate')}%`}
              description="Additional information to compare with Clever Commission Rate."
              type="info"
            />
          </Form.Item>
        )}

        {!isNewForm && !!formRef.getFieldValue('commission_minimum_rate') && (
          <Form.Item label="Minimum Rate">
            <Alert
              message={`${formRef.getFieldValue('commission_minimum_rate')}%`}
              description="Additional information about Minimum Commission Rate."
              type="info"
            />
          </Form.Item>
        )}

        {!isNewForm && !!formRef.getFieldValue('commission_maximum_rate') && (
          <Form.Item label="Maximum Rate">
            <Alert
              message={`${formRef.getFieldValue('commission_maximum_rate')}%`}
              description="Additional information about Maximum Commission Rate."
              type="info"
            />
          </Form.Item>
        )}

        <Form.Item
          label="Content"
          name="summary"
          rules={[
            {
              required: true,
              message: 'Content is required',
            },
            {
              max: 2500,
              message: 'Content cannot exceed 2500 characters',
            },
          ]}>
          <TextArea placeholder="Enter your Cash Reward's content" rows={5} />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              {locales.web_actions.save}
            </Button>
            <Button type="default" onClick={onCancel}>
              {locales.web_actions.cancel}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}
