import _cloneDeep from 'lodash/cloneDeep';

export const convertBoolean = (value) => {
  if (value === 'null' || value === 'undefined') {
    return false;
  }

  if (value === 'true') return true;
  if (value === 'false') return false;
  return !!value;
};

const convertStringFields = [
  'name',
  'title',
  'sub_title',
  'tracking_url',
  'summary',
];
const convertBooleanFields = ['active', 'instant', 'up_to'];

export const convertString = (value) => {
  if (value === 'null' || value === 'undefined') {
    return '';
  }
  return value;
};

export const convertFields = (values) => {
  if (typeof values !== 'object') return values;
  const cloned = _cloneDeep(values);
  const keys = Object.keys(cloned);
  keys.forEach((key) => {
    if (convertBooleanFields.includes(key)) {
      cloned[key] = convertBoolean(cloned[key]);
    } else if (convertStringFields.includes(key)) {
      cloned[key] = convertString(cloned[key]);
    }
  });
  return cloned;
};
