import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Space, Skeleton, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import locales from 'locales';
import {
  createResource,
  updateResource,
  showResource,
} from 'services/resource';

import SwitchInput from 'components/inputs/SwitchInput';
import ColorPicker from '../ColorPicker';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default function HubTopicForm(props) {
  const { id } = props;
  const isNewForm = !id;

  const history = useHistory();
  const [formRef] = Form.useForm();

  const { basePath, resourceModel } = useStoreState((state) => state.resource);

  const [state, setState] = useState({
    isIniting: true,
    loading: false,
    errors: {},
    pickerVisible: false,
  });

  useEffect(() => {
    async function initForm() {
      if (isNewForm) {
        setState((prev) => ({ ...prev, isIniting: false }));
        return;
      }
      if (!state.isIniting) setState((prev) => ({ ...prev, isIniting: true }));
      const resp = await showResource({ resourceModel, id });
      if (resp.success) {
        const entry = resp.data.record;
        formRef.setFieldsValue({
          active: entry.active,
          name: entry.name,
          code: entry.code,
          icon: entry.icon,
          color: entry.color,
          priority: entry.priority,
        });
      } else {
      }
      setState((prev) => ({ ...prev, isIniting: false }));
    }

    if (resourceModel) initForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceModel, id]);

  const onFinish = React.useCallback(
    async (formData) => {
      setState((prev) => ({ ...prev, loading: true, errors: {} }));
      const resp = isNewForm
        ? await createResource({
            resourceModel,
            ...formData,
          })
        : await updateResource({
            resourceModel,
            ...formData,
            id: id,
          });

      if (resp.success) {
        setState((prev) => ({ ...prev, loading: false }));
        history.replace(basePath);
      } else {
        setState((prev) => ({ ...prev, loading: false, errors: resp.errors }));
      }
    },
    [history, isNewForm, basePath, resourceModel, id],
  );
  const onCancel = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  if (state.isIniting) {
    return (
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
      </Space>
    );
  }

  return (
    <>
      <Form
        {...layout}
        form={formRef}
        onFinish={onFinish}
        initialValues={{ active: false }}>
        <Form.Item label="Active" name="active">
          <SwitchInput
            onChange={(value) => {
              formRef.setFieldsValue(value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          help={
            state.errors['name'] && Array.from(state.errors['name']).join(', ')
          }
          rules={[
            {
              required: true,
              message: 'Name is required',
            },
            {
              max: 50,
              message: 'Name cannot exceed 50 characters',
            },
          ]}>
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="Code"
          name="code"
          help={
            state.errors['code'] && Array.from(state.errors['code']).join(', ')
          }
          disabled={!isNewForm}
          rules={[
            {
              required: true,
              message: 'Code is required',
            },
            {
              max: 50,
              message: 'Code cannot exceed 50 characters',
            },
          ]}>
          <Input placeholder="Code" />
        </Form.Item>

        <Form.Item
          label="Icon"
          name="icon"
          rules={[
            {
              required: true,
              message: 'Icon is required',
            },
            {
              max: 10,
              message: 'Label cannot exceed 10 characters',
            },
          ]}>
          <Input placeholder="Enter your icon" />
        </Form.Item>

        <Form.Item
          label="Background color"
          name="color"
          rules={[
            {
              required: true,
              message: 'Background color is required',
            },
          ]}>
          <>
            <Button
              type="default"
              style={{
                width: '100%',
                textAlign: 'left',
              }}
              onClick={() =>
                setState((prev) => ({ ...prev, pickerVisible: true }))
              }>
              {formRef.getFieldValue('color')?.length ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: 12,
                      height: 12,
                      borderRadius: 2,
                      backgroundColor: formRef.getFieldValue('color'),
                      marginRight: 5,
                    }}
                  />
                  <span>{formRef.getFieldValue('color')}</span>
                </div>
              ) : (
                <span style={{ color: '#bfbfbf' }}>
                  Enter your background color
                </span>
              )}
            </Button>
            <ColorPicker
              visible={state.pickerVisible}
              color={formRef.getFieldValue('color')}
              onChangeComplete={(color) => {
                formRef.setFieldsValue({ color });
                setState((prev) => ({ ...prev, pickerVisible: false }));
              }}
            />
          </>
        </Form.Item>

        <Form.Item label="Priority" name="priority">
          <InputNumber placeholder="Priority" min={0} max={999} />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" loading={state.loading}>
              {locales.web_actions.save}
            </Button>
            <Button type="default" onClick={onCancel} loading={state.loading}>
              {locales.web_actions.cancel}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}
