import Cookies from 'js-cookie';
import SESSION_TOKEN_KEY from 'constants';

export function setAuthToken(token) {
  Cookies.set(SESSION_TOKEN_KEY, token);
  return token;
}

export function getAuthToken() {
  return Cookies.get(SESSION_TOKEN_KEY);
}

export function clearAuthToken() {
  Cookies.remove(SESSION_TOKEN_KEY);
}
