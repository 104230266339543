import React, { useEffect } from 'react';
import { Form, Input, Button, Space, Skeleton, Select } from 'antd';
import locales from 'locales';
import { useHistory } from 'react-router-dom';
import {
  createResource,
  updateResource,
  getAllHubTopics,
  showResource,
} from 'services/resource';
import { useStoreState } from 'easy-peasy';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import SwitchInput from 'components/inputs/SwitchInput';
const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default function PollForm(props) {
  const { id } = props;
  const [formRef] = Form.useForm();
  const history = useHistory();

  const { basePath, resourceModel } = useStoreState((state) => state.resource);

  const [isLoading, setLoading] = React.useState(false);
  const [hubTopics, setHubTopics] = React.useState([]);
  const [isIniting, setInitingForm] = React.useState(true);

  const isNewForm = !id;

  useEffect(() => {
    async function initForm() {
      setInitingForm(true);
      const topics = await getAllHubTopics();
      setHubTopics(
        topics.data.hub_topics.map((e) => ({ value: e.id, label: e.name })),
      );

      if (isNewForm) {
        formRef.setFieldsValue({
          active: false,
          title: '',
          hub_topic_id: undefined,
          poll_type: 'single_choice',
          poll_choices: [],
        });
        setInitingForm(false);
        return;
      }
      const resp = await showResource({ resourceModel, id });

      if (resp.success) {
        const entry = resp.data.record;
        formRef.setFieldsValue({
          active: entry.active,
          title: entry.title,
          hub_topic_id: entry.hub_topic.id,
          poll_type: entry.poll_type,
          poll_choices: entry.poll_choices,
        });
      } else {
      }
      setInitingForm(false);
    }

    if (resourceModel) initForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceModel, id]);

  const onFinish = React.useCallback(
    async (formData) => {
      setLoading(true);
      const resp = isNewForm
        ? await createResource({ resourceModel, ...formData, id })
        : await updateResource({ resourceModel, ...formData, id });
      setLoading(false);
      if (resp.success) {
        history.push(basePath);
      } else {
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, isNewForm, basePath, resourceModel],
  );
  const onCancel = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  if (isIniting) {
    return (
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
      </Space>
    );
  }

  return (
    <>
      <Form {...layout} form={formRef} onFinish={onFinish}>
        <Form.Item label="Active" name="active">
          <SwitchInput
            onChange={(value) => {
              formRef.setFieldsValue(value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: 'Title is required',
            },
            {
              max: 100,
              message: 'Title cannot exceed 100 characters',
            },
          ]}>
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item
          label="Hub Topic"
          name="hub_topic_id"
          rules={[
            {
              required: true,
              message: 'Hub Topic is required',
            },
          ]}>
          <Select
            name="hub_topic_id"
            style={{ width: '100%' }}
            options={hubTopics}
            onChange={(value) => {
              formRef.setFieldsValue(value);
            }}
            placeholder={'Please select a topic'}></Select>
        </Form.Item>
        {/* <Form.Item
          label="Poll Type"
          name="poll_type"
          rules={[
            {
              required: true,
              message: 'Poll Type is required',
            },
          ]}
        >
          <Select
            name="poll_type"
            style={{ width: '100%' }}
            options={[
              { value: 'single_choice', label: 'Single Choice' },
              { value: 'multiple_choice', label: 'Multiple Choice' },
            ]}
            onChange={(value) => {
              formRef.setFieldsValue(value)
            }}
          ></Select>
        </Form.Item> */}
        <Form.Item label="Poll Type" name="poll_type" hidden>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item label="Options">
          <Form.List name="poll_choices">
            {(fields, { add, remove }) => {
              return (
                <Space direction={'vertical'}>
                  {fields.map((field) => (
                    <Space
                      key={field.key}
                      align="start"
                      style={{ width: 400, alignItems: 'center' }}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'content']}
                        fieldKey={[field.fieldKey, 'content']}
                        rules={[{ required: true, message: 'Missing content' }]}
                        style={{ margin: 0 }}>
                        <TextArea
                          placeholder="Content"
                          style={{ width: 350 }}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}>
                      <PlusOutlined /> Add Option
                    </Button>
                  </Form.Item>
                </Space>
              );
            }}
          </Form.List>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              {locales.web_actions.save}
            </Button>
            <Button type="default" onClick={onCancel}>
              {locales.web_actions.cancel}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}
