import AdminModel from './admin.model'
import AppModel from './app.model'
import ResourceModel from './resource.model'
export const StoreModel = {
  admin: AdminModel,
  app: AppModel,
  resource: ResourceModel,
}

export default StoreModel
