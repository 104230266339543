import React from 'react';
import { Layout, Menu, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  PicCenterOutlined,
  SolutionOutlined,
  UnorderedListOutlined,
  ReadOutlined,
  BookOutlined,
  TrophyOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
const { Sider } = Layout;

export default function SideNavigation() {
  const history = useHistory();
  const onMenuItemClick = React.useCallback(
    ({ key }) => {
      history.push(key);
    },
    [history],
  );

  return (
    <Sider
      width={250}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}>
      <Typography.Title
        level={1}
        style={{
          padding: '12px 20px',
          color: '#fff',
          textAlign: 'center',
          background: '#000',
          margin: 0,
        }}>
        AircreX
      </Typography.Title>

      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[history.location.pathname]}
        onClick={onMenuItemClick}>
        <Menu.Item key="/posts" icon={<SolutionOutlined />}>
          Posts
        </Menu.Item>
        <Menu.Item key="/polls" icon={<UnorderedListOutlined />}>
          Polls
        </Menu.Item>
        <Menu.Item key="/hub-topics" icon={<PicCenterOutlined />}>
          Interests
        </Menu.Item>
        <Menu.Item key="/blogs" icon={<ReadOutlined />}>
          Blogs
        </Menu.Item>
        <Menu.Item key="/daily-insights" icon={<BookOutlined />}>
          Daily Insights
        </Menu.Item>
        <Menu.Item key="/cash-reward" icon={<TrophyOutlined />}>
          Cash Reward
        </Menu.Item>
        <Menu.Item key="/your-idea" icon={<ShareAltOutlined />}>
          Share Your Idea
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
