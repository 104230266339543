import React from 'react';
import locales from 'locales';
import { Button } from 'antd';
import { Popconfirm } from 'antd';
import { useStoreState } from 'easy-peasy';
import { toggleResource } from 'services/resource';

export default function ToogleActivationButton(props) {
  const { resourceModel } = useStoreState((state) => state.resource);
  const { id, active, toggle, onDone = () => {} } = props;
  const onConfirm = async () => {
    let resp = {};

    if (toggle) {
      resp = await toggle(id);
    } else {
      resp = await toggleResource({ resourceModel, id });
    }
    if (resp.success) {
      onDone();
    }
  };

  return (
    <Popconfirm
      placement="topLeft"
      title={
        active
          ? locales.deactivateConfirmMessage
          : locales.activateConfirmMessage
      }
      onConfirm={onConfirm}
      okText={locales.yes}
      cancelText={locales.no}>
      <Button
        type="default"
        style={{
          background: active ? 'orange' : 'green',
          color: '#fff',
          minWidth: 110,
        }}>
        {active ? locales.web_actions.deactivate : locales.web_actions.activate}
      </Button>
    </Popconfirm>
  );
}
