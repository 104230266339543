import React from 'react';
import { Form, Input, Button, Space } from 'antd';
import locales from 'locales';
import { useHistory } from 'react-router-dom';
import { createResource, updateResource } from 'services/resource';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default function ResourceForm(props) {
  const { basePath, id } = props;
  const isNewForm = !id;

  const [form] = Form.useForm();
  const history = useHistory();

  const [isLoading, setLoading] = React.useState(false);

  const onFinish = React.useCallback(
    async (formData) => {
      setLoading(true);
      const resp = isNewForm
        ? await createResource(formData)
        : await updateResource(formData);
      if (resp.success) {
        history.push(basePath);
      } else {
      }
      setLoading(false);
    },
    [history, isNewForm, basePath],
  );

  const onCancel = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  return (
    <>
      <Form {...layout} form={form} onFinish={onFinish}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Email is required',
            },
          ]}>
          <Input placeholder="Email Address" />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Name is required',
            },
          ]}>
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              {locales.web_actions.save}
            </Button>
            <Button type="default" onClick={onCancel}>
              {locales.web_actions.cancel}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}
