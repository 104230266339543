import React from 'react'
import { PageHeader } from 'antd'
import { Link } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'

import PageContent from 'components/PageContent'

import ResourceForm from './form'

function itemRender(route, params, routes, paths) {
  const last = routes.indexOf(route) === routes.length - 1
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  )
}
export default function EditResource(props) {
  const { formComponent } = props
  const { basePath, resourceName } = useStoreState(
    (state) => state.resource
  )
  const { params } = useRouteMatch()

  const Form = formComponent || ResourceForm
  const routes = [
    {
      path: basePath,
      breadcrumbName: resourceName,
    },
    {
      breadcrumbName: 'Edit',
    },
  ]

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={`Edit ${resourceName}`}
        backIcon={true}
        breadcrumb={{ routes, itemRender }}
      ></PageHeader>
      <PageContent>
        <Form {...props} id={params.id} />
      </PageContent>
    </>
  )
}
