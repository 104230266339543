import { createAxios } from 'config/axios';
import { getAuthToken } from 'utils/auth.util';

function errorHandler(error) {
  const { response } = error;
  let errors = {};
  try {
    if (response && response.data && response.data.errors) {
      errors = response.data.errors.message;
    }
    return {
      success: false,
      errors,
    };
  } catch (err) {
    return {
      success: false,
      errors: 'Unexpected server error',
    };
  }
}

export async function signIn({ email, password }) {
  try {
    const resp = await createAxios({ token: getAuthToken() }).post('/login', {
      email,
      password,
    });
    return {
      success: true,
      data: resp.data,
      error: null,
    };
  } catch (error) {
    return errorHandler(error);
  }
}
