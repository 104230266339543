import React from 'react';
import { Tag } from 'antd';
import { useStoreActions } from 'easy-peasy';

import DashboardLayout from 'layouts/Dashboard';
import { togglePressRelease } from 'services/resource';
import Resource from 'components/Resources';
import BlogForm from 'components/forms/BlogForm';
import ToggleButton from 'components/ToogleActivationButton';

export default function BlogPage() {
  const resetParams = useStoreActions(
    (actions) => actions.resource.resetParams,
  );

  return (
    <DashboardLayout>
      <Resource
        resourceModel="blogs"
        basePath="/blogs"
        resourceName="Blog"
        formComponent={BlogForm}
        columns={[
          {
            title: 'Thumbnail',
            dataIndex: 'photo_url',
            key: 'photo_url',
            render: (photo_url, record) =>
              photo_url && <img src={photo_url} width={100} alt="" />,
          },
          {
            title: 'Title',
            dataIndex: 'content',
            key: 'content',
            render: (text, record) => text,
          },
          {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) =>
              record.active ? (
                <Tag color="green">Active</Tag>
              ) : (
                <Tag color="red">Inactive</Tag>
              ),
          },
          {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record) => new Date(text).toLocaleString(),
          },
          {
            title: 'Is Press Release',
            dataIndex: 'is_press_release',
            key: 'is_press_release',
            render: (isPressRelease, record) => (
              <ToggleButton
                active={isPressRelease}
                id={record.id}
                toggle={togglePressRelease}
                onDone={() => resetParams()}
              />
            ),
          },
        ]}
      />
    </DashboardLayout>
  );
}
