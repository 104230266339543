import React from "react";

import DashboardLayout from "layouts/Dashboard";
import Resource from "components/Resources";
import DailyInsightsForm from "components/forms/DailyInsightsForm";

function DailyInsightsPage(props) {
  return (
    <DashboardLayout>
      <Resource
        resourceModel="daily_insights"
        basePath="/daily-insights"
        resourceName="Daily Insights"
        formComponent={DailyInsightsForm}
        columns={[
          {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (text, record) => text,
          },
          {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (text, record) => text,
          },
          {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text, record) => text,
          },
        ]}
      />
    </DashboardLayout>
  );
}

export default DailyInsightsPage;
