import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';

import DashboardLayout from 'layouts/Dashboard';
import Resource from 'components/AffiliateResource';
import CashRewardForm from 'components/forms/CashRewardForm';
import {
  transformTypes,
  getTypeName,
  MERCHANT_TYPES,
  PROVIDER_TYPES,
} from 'utils/affiliate.util';
import { getCashRewardCategories } from 'services/resource';

export default function CashRewardPage() {
  const [state, setState] = useState({ loading: true, categories: [] });

  useEffect(() => {
    (async () => {
      if (!state.loading) setState((prev) => ({ ...prev, loading: true }));
      const response = await getCashRewardCategories();
      const categories = _get(response, 'data', []);

      const formatcategories = categories.map((category) => ({
        key: category,
        text: category,
        value: category,
      }));

      setState({ categories: formatcategories, loading: false });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <Resource
        resourceModel="affiliates"
        basePath="/affiliates"
        resourceName="Cash Reward"
        formComponent={CashRewardForm}
        tableLoading={state.loading}
        columns={[
          {
            title: 'Thumbnail',
            dataIndex: 'avatar_url',
            key: 'avatar_url',
            render: (avatar_url) =>
              avatar_url && <img src={avatar_url} width={100} alt="" />,
          },
          {
            title: 'Cashback (%)',
            dataIndex: 'cashback',
            key: 'cashback',
            sorter: true,
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
          },
          {
            title: 'Merchant type',
            dataIndex: 'affiliate_types',
            key: 'affiliate_types',
            render: (types) => transformTypes(types),
            filters: MERCHANT_TYPES.map((ele) => ({
              text: ele.label,
              value: ele.value,
            })),
          },
          {
            title: 'Provider',
            dataIndex: 'affiliate_provider',
            key: 'affiliate_provider',
            render: (types) => getTypeName(types),
            filters: PROVIDER_TYPES.map((ele) => ({
              text: ele.label,
              value: ele.value,
            })),
          },
          {
            title: 'Categories',
            dataIndex: 'category',
            key: 'category',
            filters: state.categories,
            filterSearch: true,
          },
        ]}
      />
    </DashboardLayout>
  );
}
