import { message } from 'antd';

import { getImageUploadUrl } from 'services/resource';

const preprocessImage = async (file, data, callback) => {
  const xhr = new XMLHttpRequest();

  xhr.open('PUT', data.signed_url, true);
  xhr.setRequestHeader('Content-Type', file.type);
  xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');

  xhr.send(file);

  xhr.onload = async () => {
    if (xhr.status < 400 && callback) {
      callback(data.get_image_url);
    } else {
      message.error('Failed to upload image, please try again!');
    }
  };

  xhr.onerror = (error) => {
    message.error('Failed to upload image, please try again!');
  };
};

export const beforeUploadImage = async (file, callback) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
    return;
  }
  const isLt3M = file.size / 1024 / 1024 < 3;
  if (!isLt3M) {
    message.error('Image must smaller than 3MB!');
    return;
  }

  const response = await getImageUploadUrl();

  await preprocessImage(file, response.data, (url) => {
    if (url && callback) {
      callback(url);
    } else {
      message.error('Failed to upload image, please try again!');
    }
  });
};
