import React from 'react';
import Quill from './Component';

const TextEditor = React.memo((props) => {
  return (
    <div id="react_quillll">
      <Quill {...props} />
    </div>
  );
});

export default TextEditor;
