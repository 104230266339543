const config = {
  development: {
    api_endpoint: 'http://localhost:3000/rexy-admin/',
    // api_endpoint: 'https://api.aircrex.com/rexy-admin/',
  },
  staging: {
    api_endpoint: 'https://api.aircrex.rexylab.com/rexy-admin/',
  },
  test: {
    api_endpoint: 'https://staging.api.aircrex.com/rexy-admin/',
  },
  production: {
    api_endpoint: 'https://api-app.helloclever.co/rexy-admin/',
  },
};
const app = config[process.env.REACT_APP_ENV || 'development'];
export default app;
