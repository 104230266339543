import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, Button, Space, Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import {
  createResource,
  updateResource,
  showResource,
} from 'services/resource';
import locales from 'locales';

import PhotoPicker from '../PhotoPicker';
import SwitchInput from '../inputs/SwitchInput';
import TextEditor from '../TextEditor';

const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default function PostForm(props) {
  const { basePath, resourceModel } = useStoreState((state) => state.resource);
  const { id } = props;
  const isNewForm = !id;
  const [isLoading, setLoading] = useState(false);
  const [formRef] = Form.useForm();
  const [isIniting, setInitingForm] = useState(true);
  const [file, setFile] = useState(undefined);
  const history = useHistory();

  const onFinish = React.useCallback(
    async (formData) => {
      setLoading(true);
      const resp = isNewForm
        ? await createResource({
            resourceModel,
            ...formData,
            photo: file,
          })
        : await updateResource({
            resourceModel,
            ...formData,
            id: id,
            photo: file,
          });
      setLoading(false);
      if (resp.success) {
        history.replace(basePath);
      } else {
      }
    },
    [history, isNewForm, file, basePath, resourceModel, id],
  );
  const onCancel = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  useEffect(() => {
    async function initForm() {
      setInitingForm(true);
      if (isNewForm) {
        setInitingForm(false);
        return;
      }
      const resp = await showResource({ resourceModel, id });
      if (resp.success) {
        const entry = resp.data.record;
        formRef.setFieldsValue({
          active: entry.active,
          content: entry.content,
          photo_url: entry.photo_url,
          photo_file_name: entry.photo_file_name,
          description: entry.description,
        });
      } else {
      }
      setInitingForm(false);
    }

    if (resourceModel) initForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceModel, id]);

  const handleChangeLabelContent = useCallback(
    (html, pureContent) => {
      formRef.setFieldsValue({ description: html });
    },
    [formRef],
  );

  if (isIniting) {
    return (
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
      </Space>
    );
  }

  return (
    <>
      <Form
        {...layout}
        form={formRef}
        onFinish={onFinish}
        initialValues={{ active: false }}>
        <Form.Item label="Active" name="active">
          <SwitchInput
            onChange={(value) => {
              formRef.setFieldsValue(value);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Photo"
          name="photo"
          rules={[
            {
              required: isNewForm,
              message: 'Photo is required',
            },
          ]}>
          <PhotoPicker
            name="photo"
            url={formRef.getFieldValue('photo_url')}
            onFileAdded={(file) => {
              setFile(file);
              formRef.setFieldsValue({
                photo: file,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Title"
          name="content"
          rules={[
            {
              required: true,
              message: 'Title is required',
            },
            {
              max: 100,
              message: 'Title cannot exceed 100 characters',
            },
          ]}>
          <TextArea placeholder="Enter your blog's title" rows={5} />
        </Form.Item>

        <Form.Item
          label="Content"
          name="description"
          rules={[
            {
              required: true,
              message: 'Content is required',
            },
          ]}>
          <TextEditor
            placeholder="Enter your blog's content"
            handleChangeLabelContent={handleChangeLabelContent}
            value={formRef.getFieldValue('description')}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              {locales.web_actions.save}
            </Button>
            <Button type="default" onClick={onCancel}>
              {locales.web_actions.cancel}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}
