import React, { useEffect, useState } from 'react';
import { Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const URL = window.URL || window.webkitURL;

function preprocessImage(file, callback) {
  if (!window.FileReader) return callback(null);
  let reader = new FileReader();
  reader.onload = (() => {
    return (e) => {
      let image = new Image();
      image.src = e.target.result;
      image.onload = (() => {
        return (e) => {
          callback(URL.createObjectURL(file));
        };
      })(image);
    };
  })(file);
  reader.readAsDataURL(file);
}

function beforeUploadFunc(file, callback) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
    return;
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
    return;
  }
  preprocessImage(file, (url) => {
    if (url) {
      callback(url);
    }
  });
  return false;
}

export default function PhotoPicker(props) {
  const { url, name, onFileAdded = () => {} } = props;
  const [imageUrl, setImageUrl] = useState(url);
  const [fileList, setFileList] = useState([]);

  const uploadProps = {
    onRemove: (file) => {
      setFileList((prevList) => {
        const index = prevList.indexOf(file);
        const newFileList = prevList.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file) => {
      beforeUploadFunc(file, (url) => {
        setImageUrl(url);
        setFileList((prevList) => {
          return [...prevList, file];
        });
        onFileAdded(file);
      });

      return false;
    },
    fileList,
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    url && setImageUrl(url);
  }, [url]);

  return (
    <Upload
      name={name || 'file'}
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      {...uploadProps}>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
}
