import React from 'react'
import DashboardLayout from 'layouts/Dashboard'
import Resource from 'components/Resources'
import PollForm from 'components/forms/PollForm'

export default function PollsPage() {
  return (
    <DashboardLayout>
      <Resource
        resourceModel="polls"
        basePath="/polls"
        resourceName="Poll"
        formComponent={PollForm}
        columns={[
          {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
          },
          {
            title: 'Hub Topic',
            dataIndex: 'hub_topic',
            key: 'hub_topic',
            render: (hub_topic) => hub_topic && hub_topic.name,
          },
          {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => (record.active ? 'Active' : 'Inactive'),
          },
          // {
          //   title: 'Created At',
          //   dataIndex: 'created_at',
          //   key: 'created_at',
          //   render: (text, record) => new Date(text).toLocaleString(),
          // },
        ]}
      />
    </DashboardLayout>
  )
}
