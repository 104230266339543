import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Space, Skeleton, Select } from 'antd';
import locales from 'locales';
import { useHistory } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import {
  createResource,
  updateResource,
  showResource,
  getAllHashTags,
  getAllHubTopics,
} from 'services/resource';
import PhotoPicker from 'components/PhotoPicker';
import SwitchInput from 'components/inputs/SwitchInput';

const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default function PostForm(props) {
  const { id } = props;

  const history = useHistory();
  const [formRef] = Form.useForm();

  const { basePath, resourceModel } = useStoreState((state) => state.resource);

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({ hashTags: [], hubTopics: [] });
  const [isIniting, setInitingForm] = useState(true);
  const [file, setFile] = useState(undefined);

  const isNewForm = !id;

  useEffect(() => {
    async function initForm() {
      setInitingForm(true);
      const [hashtags, topics] = await Promise.all([
        getAllHashTags(),
        getAllHubTopics(),
      ]);
      setData((prev) => ({
        ...prev,
        hashTags: hashtags.data.hash_tags.map((e) => ({ value: e, label: e })),
        hubTopics: topics.data.hub_topics.map((e) => ({
          value: e.id,
          label: e.name,
        })),
      }));

      if (isNewForm) {
        setInitingForm(false);
        return;
      }
      const resp = await showResource({ resourceModel, id });
      if (resp.success) {
        const entry = resp.data.record;
        formRef.setFieldsValue({
          active: entry.active,
          content: entry.content,
          photo_url: entry.photo_url,
          photo_file_name: entry.photo_file_name,
          hub_topic_id: entry.hub_topic.id,
          post_hash_tags: entry.post_hash_tags.map((e) => e.hash_tag),
        });
      } else {
      }
      setInitingForm(false);
    }

    if (resourceModel) initForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceModel, id]);

  const transformHashTags = (post_hash_tags) => {
    return post_hash_tags.map((tag) => ({
      hash_tag: tag,
    }));
  };

  const onFinish = React.useCallback(
    async (formData) => {
      const post_hash_tags = transformHashTags(formData.post_hash_tags || []);
      const hub_topic_id = formData.hub_topic_id.toString();

      setLoading(true);
      const resp = isNewForm
        ? await createResource({
            resourceModel,
            ...formData,
            photo: file,
            post_hash_tags,
            hub_topic_id,
          })
        : await updateResource({
            resourceModel,
            ...formData,
            id: id,
            photo: file,
            post_hash_tags,
            hub_topic_id,
          });
      setLoading(false);
      if (resp.success) {
        history.replace(basePath);
      } else {
      }
    },
    [history, isNewForm, file, basePath, resourceModel, id],
  );

  const onCancel = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  if (isIniting) {
    return (
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
      </Space>
    );
  }

  return (
    <>
      <Form
        {...layout}
        form={formRef}
        onFinish={onFinish}
        initialValues={{ active: false }}>
        <Form.Item label="Active" name="active">
          <SwitchInput
            onChange={(value) => {
              formRef.setFieldsValue(value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Photo"
          name="photo"
          rules={[
            {
              required: isNewForm,
              message: 'Photo is required',
            },
          ]}>
          <PhotoPicker
            name="photo"
            url={formRef.getFieldValue('photo_url')}
            onFileAdded={(file) => {
              setFile(file);
              formRef.setFieldsValue({
                photo: file,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Content"
          name="content"
          rules={[
            {
              required: true,
              message: 'Content is required',
            },
            {
              max: 1000,
              message: 'Content cannot exceed 1000 characters',
            },
          ]}>
          <TextArea placeholder="Content" rows={5} />
        </Form.Item>
        <Form.Item
          label="Hub Topic"
          name="hub_topic_id"
          rules={[
            {
              required: true,
              message: 'Hub Topic is required',
            },
          ]}>
          <Select
            name="hub_topic_id"
            style={{ width: '100%' }}
            options={data.hubTopics}
            onChange={(value) => {
              formRef.setFieldsValue(value);
            }}
            placeholder={'Please select a topic'}></Select>
        </Form.Item>

        <Form.Item label="Hash Tags" name="post_hash_tags">
          <Select
            mode="tags"
            name="post_hash_tags"
            style={{ width: '100%' }}
            options={data.hashTags}
            onChange={(value) => {
              formRef.setFieldsValue(value);
            }}></Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              {locales.web_actions.save}
            </Button>
            <Button type="default" onClick={onCancel}>
              {locales.web_actions.cancel}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}
