import React, { useEffect, useMemo } from 'react';
import { Table, Button, Space } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from 'easy-peasy';

import './style.scss';
import locales from 'locales';

import ToggleActivationButton from '../ToogleActivationButton';
import DeleteButton from '../DeleteButton';
import EditButton from '../EditButton';

export default function ResourceTable(props) {
  const resetParams = useStoreActions(
    (actions) => actions.resource.resetParams,
  );

  const changeParams = useStoreActions(
    (actions) => actions.resource.resetParams,
  );
  const resource = useStoreState((state) => state.resource);

  const {
    isLoading,
    resourceModel,
    basePath,
    listing: { pagination, records },
  } = resource;

  const columns = useMemo(() => {
    if (props.hideAction) {
      return [...props.columns];
    } else {
      return [
        ...props.columns,
        {
          title: locales.action,
          key: 'action',
          width: 320,
          render: (text, record) => (
            <Space size="middle" style={{ width: '100%' }}>
              <EditButton url={`${basePath}/${record.id}/edit`} />
              {!props.hideActive && (
                <ToggleActivationButton
                  {...record}
                  onDone={() => resetParams()}
                />
              )}
              <DeleteButton
                allowDelete={text.is_press_release}
                id={record.id}
                onDone={() => resetParams()}
              />
            </Space>
          ),
        },
      ];
    }
  }, [
    props.hideAction,
    props.columns,
    props.hideActive,
    resetParams,
    basePath,
  ]);

  const handleTableChange = React.useCallback(
    (pagination, filters, sorter) => {
      changeParams({
        sortField: sorter.field,
        sortOrder: sorter.order,
        pagination,
        ...filters,
      });
    },
    [changeParams],
  );

  useEffect(() => {
    resourceModel && resetParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceModel]);

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <Space>
        <Button onClick={() => resetParams()}>
          <ReloadOutlined />
        </Button>
      </Space>
      <Table
        columns={columns}
        bordered
        rowKey={(record) => record.id}
        dataSource={records}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
        rowClassName="table-row-wrapper"
        scroll={{ x: (columns.length - 1) * 270 + 320, y: 500 }}
      />
    </Space>
  );
}
