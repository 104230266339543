import React from 'react'
import locales from 'locales'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

export default function EditButton(props) {
  const { url } = props
  const history = useHistory()
  return (
    <Button type="primary" onClick={() => history.push(url)}>
      {locales.web_actions.edit}
    </Button>
  )
}
