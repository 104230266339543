import LocalizedStrings from "react-localization";
import en from "./en.json";
import vi from "./vi.json";

let strings = new LocalizedStrings({
  en,
  vi,
});

strings.setLanguage("en");

export default strings;
