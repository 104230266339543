import React from "react";
import DefaultLayout from "layouts/default";
import SignIn from "components/SignIn";

function SignInPage() {
  return (
    <DefaultLayout>
      <SignIn />
    </DefaultLayout>
  );
}

export default SignInPage;
