import React from 'react';
import { Form, Button, Space } from 'antd';
import { useHistory } from 'react-router-dom';

import { createResource, updateResource } from 'services/resource';
import locales from 'locales';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default function ResourceForm(props) {
  const { basePath, id, formItems } = props;
  const isNewForm = !id;
  const history = useHistory();
  const [form] = Form.useForm();

  const [isLoading, setLoading] = React.useState(false);

  const onFinish = React.useCallback(
    async (formData) => {
      setLoading(true);
      const resp = isNewForm
        ? await createResource(formData)
        : await updateResource(formData);
      if (resp.success) {
        history.push(basePath);
      }
      setLoading(false);
    },
    [history, isNewForm, basePath],
  );
  const onCancel = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  return (
    <>
      <Form {...layout} form={form} onFinish={onFinish}>
        {formItems}

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              {locales.web_actions.save}
            </Button>
            <Button type="default" onClick={onCancel}>
              {locales.web_actions.cancel}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}
