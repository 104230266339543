import React from 'react'
import DashboardLayout from 'layouts/Dashboard'
import Resource from 'components/Resources'
import AdminUserForm from 'components/forms/AdminUserForm'
export default function AdminsPage() {
  return (
    <DashboardLayout>
      <Resource
        resourceModel="users"
        basePath="/admins"
        resourceName="Admin Users"
        formComponent={AdminUserForm}
        columns={[
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
          },
          {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => (record.active ? 'Active' : 'Inactive'),
          },
        ]}
      />
    </DashboardLayout>
  )
}
