import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button } from 'antd'

export default function NewButton(props) {
  const history = useHistory()
  let { path } = useRouteMatch()

  return (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      key={`${path}/new`}
      onClick={() => history.push(`${path}/new`)}
    >
      {props.title}
    </Button>
  )
}
