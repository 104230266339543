import { createAxios } from 'config/axios';
import { getAuthToken } from 'utils/auth.util';
import { notification } from 'antd';

export function resolveDataForm(data, onProgress = () => {}) {
  let config = {};

  const haveFile = Object.values(data).some(
    (e) => e && e.toString() === '[object File]',
  );
  let formData = data;
  if (haveFile) {
    config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        onProgress(percentCompleted);
      },
    };
    formData = new FormData();
    formData = resolveDataKey(formData, data);
  }
  return {
    formData,
    config,
  };
}

function resolveDataKey(formData, object, prefix = '') {
  Object.keys(object).forEach((key) => {
    if (Array.isArray(object[key])) {
      object[key].forEach((child) => {
        resolveDataKey(formData, child, key + '[]');
      });
    } else {
      const dataKey = prefix ? `${prefix}[${key}]` : key;
      formData.append(dataKey, object[key]);
    }
  });
  return formData;
}

function errorHandler(error) {
  const { response } = error;
  let errors = {};
  notification.error({
    message: 'Failed',
    description: 'Something wrong happened. Please try again.',
  });
  try {
    if (response && response.data && response.data.errors) {
      errors = response.data.errors;
    }
    return {
      success: false,
      errors,
    };
  } catch (err) {
    return {
      success: false,
      errors: 'Unexpected server error',
    };
  }
}

export async function createResource({
  resourceModel,
  onProgress = () => {},
  ...data
}) {
  const { formData, config } = resolveDataForm(data, onProgress);
  try {
    const resp = await createAxios({ token: getAuthToken() }).post(
      `/${resourceModel}`,
      formData,
      config,
    );
    return {
      success: true,
      data: resp.data,
      errors: null,
    };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function updateResource({
  resourceModel,
  id,
  onProgress = () => {},
  ...data
}) {
  try {
    const { formData, config } = resolveDataForm(data, onProgress);

    const resp = await createAxios({ token: getAuthToken() }).put(
      `/${resourceModel}/${id}`,
      formData,
      config,
    );
    return {
      success: true,
      data: resp.data,
      errors: null,
    };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function toggleResource({ resourceModel, id }) {
  try {
    const resp = await createAxios({ token: getAuthToken() }).patch(
      `/${resourceModel}/${id}`,
    );
    return {
      success: true,
      data: resp.data,
      errors: null,
    };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function showResource({ resourceModel, id }) {
  try {
    const resp = await createAxios({ token: getAuthToken() }).get(
      `/${resourceModel}/${id}/edit`,
    );
    return {
      success: true,
      data: resp.data,
      errors: null,
    };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function listResource({ resourceModel, ...query }) {
  try {
    const resp = await createAxios({
      token: getAuthToken(),
    }).get(`/${resourceModel}`, { params: query });

    return {
      success: true,
      data: resp.data,
      errors: null,
    };
  } catch (error) {
    if (error.response.status === 401) {
      window.location = '/sign-in';
    }
    return {
      ...errorHandler(error),
      data: { records: [], pagination: {}, ratio_user: null },
    };
  }
}

export async function deleteResource({ resourceModel, id }) {
  try {
    const resp = await createAxios({ token: getAuthToken() }).delete(
      `/${resourceModel}/${id}`,
    );
    return {
      success: true,
      data: resp.data,
      errors: null,
    };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function getAllHubTopics() {
  try {
    const resp = await createAxios({ token: getAuthToken() }).get(
      `/extra-gate/hub-topics`,
    );
    return {
      success: true,
      data: resp.data,
      errors: null,
    };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function getAllLabel(model) {
  try {
    const resp = await createAxios({ token: getAuthToken() }).get(
      `/extra-gate/${model}/labels`,
    );
    return {
      success: true,
      data: resp.data,
      errors: null,
    };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function getAllHashTags() {
  try {
    const resp = await createAxios({ token: getAuthToken() }).get(
      `/extra-gate/posts/hash_tags`,
    );
    return {
      success: true,
      data: resp.data,
      errors: null,
    };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function getImageUploadUrl() {
  try {
    const resp = await createAxios({ token: getAuthToken() }).get(
      '/extra-gate/signed-url/blog',
    );
    return {
      success: true,
      data: resp.data,
    };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function togglePressRelease(id) {
  try {
    const resp = await createAxios({ token: getAuthToken() }).patch(
      `/extra-gate/blogs/toggle-press-release`,
      { id },
    );
    return {
      success: true,
      data: resp.data,
      errors: null,
    };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function getCashRewardCategories() {
  try {
    const resp = await createAxios({ token: getAuthToken() }).get(
      '/extra-gate/affiliate/list-categories',
    );

    return {
      success: true,
      data: resp.data,
      errors: null,
    };
  } catch (error) {
    return { ...errorHandler(error), data: [] };
  }
}

export async function refreshMerchant() {
  try {
    await createAxios({ token: getAuthToken() }).post(
      '/extra-gate/affiliate/refresh',
    );
    return { success: true };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function applyAllUserRatio(body) {
  try {
    await createAxios({ token: getAuthToken() }).post(
      '/extra-gate/affiliate/ratio',
      body,
    );

    return {
      success: true,
      errors: null,
    };
  } catch (error) {
    return { ...errorHandler(error), success: false };
  }
}

export async function getYourIdeaDetail(id) {
  try {
    const resp = await createAxios({ token: getAuthToken() }).get(
      `/ideas/${id}/edit`,
    );
    return {
      success: true,
      data: resp.data.record,
      errors: null,
    };
  } catch (error) {
    return {
      success: false,
      data: {},
      errors: error,
    };
  }
}
