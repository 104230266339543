import React, { createRef } from 'react';
import { message, Spin, Space } from 'antd';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';

import './index.scss';

import { beforeUploadImage } from 'utils/imageUpload.util';

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);

const Image = Quill.import('formats/image');
Image.className = 'blog-image-custom-class';

// register custom Image
Quill.register(Image, true);
class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.state = {
      loading: false,
    };
  }

  handleChange = (html) => {
    const pureContent = this.extractContent(html);
    this.props.handleChangeLabelContent(html, pureContent.trim());
  };

  extractContent = (s) => {
    let p = document.createElement('p');
    p.innerHTML = s;
    let children = p.querySelectorAll('*');
    for (let i = 0; i < children.length; i++) {
      if (children[i].textContent) children[i].textContent += ' ';
      else children[i].innerText += ' ';
    }
    return [p.textContent || p.innerText].toString().replace(/ +/g, ' ');
  };

  imageHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];

      this.setState({ loading: true });

      await beforeUploadImage(file, this.afterUpload);
    };
  };

  imageDropAndPaste = async (imageDataUrl, type, imageData) => {
    const filename = 'paste_image.png';
    const file = imageData.toFile(filename);

    this.setState({ loading: true });

    await beforeUploadImage(file, this.afterUpload);
  };

  afterUpload = (url) => {
    message.success('Image upload success');

    const range = this.ref.current.getEditorSelection();

    this.ref.current.getEditor().insertEmbed(range.index, 'image', url);
    this.setState({ loading: false });
  };

  render() {
    const { loading } = this.state;

    return (
      <div style={{ position: 'relative' }}>
        {loading && (
          <Space
            align="center"
            size="middle"
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 10,
              backgroundColor: '#F5F5F570',
              justifyContent: 'center',
            }}>
            <Spin />
          </Space>
        )}
        <ReactQuill
          ref={this.ref}
          theme="snow"
          onChange={this.handleChange}
          value={this.props.value}
          modules={{
            toolbar: {
              container: [
                [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }],
                [{ font: [] }],
                [
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'code-block',
                ],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
                ['link', 'image'],
              ],
              handlers: {
                image: this.imageHandler,
              },
            },

            imageDropAndPaste: {
              handler: this.imageDropAndPaste,
            },
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
          }}
          formats={Editor.formats}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

Editor.formats = [
  'header',
  'font',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'color',
  'background',
  'align',
  'list',
  'ordered',
  'bullet',
  'indent',
  'link',
  'image',
];

Editor.propTypes = {
  placeholder: PropTypes.string,
};

export default Editor;
