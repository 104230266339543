import React from 'react';
import DashboardLayout from 'layouts/Dashboard';
import Resource from 'components/Resources';
import HubTopicForm from 'components/forms/HubTopicForm';

export default function HubTopicsPage() {
  return (
    <DashboardLayout>
      <Resource
        resourceModel="hub_topics"
        basePath="/hub-topics"
        resourceName="Hub Topic"
        formComponent={HubTopicForm}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
          },
          {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
          },
          {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => (record.active ? 'Active' : 'Inactive'),
          },
        ]}
      />
    </DashboardLayout>
  );
}
