import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Button } from 'antd';

function ColorPicker({ visible, color: defaultColor, onChangeComplete }) {
  const [color, setColor] = useState(defaultColor);

  if (!visible) return null;
  return (
    <div style={{ position: 'absolute', zIndex: 2 }}>
      <ChromePicker
        disableAlpha
        color={color}
        onChange={({ hex }) => setColor(hex)}
      />
      <Button
        type="primary"
        style={{ width: 225 }}
        onClick={() => onChangeComplete(color)}>
        Done
      </Button>
    </div>
  );
}

export default ColorPicker;
