import React from 'react'
import DashboardLayout from 'layouts/Dashboard'
import { Typography } from 'antd'
import PageContent from 'components/PageContent'

function DashboardPage() {
  return (
    <DashboardLayout>
      <PageContent>
        <Typography.Title
          level={1}
          style={{ color: '#777', margin: '40px auto', textAlign: 'center' }}
        >
          {'Welcome to AircreX Admin Panel'}
        </Typography.Title>
      </PageContent>
    </DashboardLayout>
  )
}

export default DashboardPage
