export const MERCHANT_TYPES = [
  { label: 'Banner', value: 'banner' },
  { label: 'Trending', value: 'trending' },
  { label: 'Lastest Offer', value: 'lastest' },
  { label: 'Recommended', value: 'recommended' },
  { label: "Staff's Pick", value: 'staff_pick' },
];

export const PROVIDER_TYPES = [
  { label: 'Rakuten 3858861', value: 'rakuten_3858861' },
  { label: 'Rakuten 3916105', value: 'rakuten_3916105' },
  { label: 'Commission Factory', value: 'commission_factory' },
  { label: 'Clever', value: 'clever_pay' },
];

export const getTypeName = (type) => {
  switch (type) {
    case 'banner':
      return 'Banner';
    case 'trending':
      return 'Trending';
    case 'lastest':
      return 'Lastest Offer';
    case 'recommended':
      return 'Recommended';
    case 'staff_pick':
      return "Staff's Pick";
    case 'rakuten':
      return 'Rakuten';
    case 'rakuten_3858861':
      return 'Rakuten 3858861';
    case 'rakuten_3916105':
      return 'Rakuten 3916105';
    case 'commission_factory':
      return 'Commission Factory';
    case 'clever_pay':
      return 'Clever';
    default:
      return '';
  }
};

export const transformTypes = (types = []) => {
  return types.map((type) => getTypeName(type)).join(', ');
};

export const parseTypeToOptions = (types = []) => {
  return types.map((type) => ({ label: getTypeName(type), value: type }));
};
