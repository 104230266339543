import React, { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { PageHeader } from 'antd';
import { useRouteMatch, Switch } from 'react-router-dom';

import PageContent from 'components/PageContent';
import PrivateRoute from 'components/PrivateRoute';
import NoMatch from 'components/NoMatch';

import NewPage from './new';
import EditPage from './edit';
import ResourceTable from './table';
import NewButton from '../NewButton';

export default function ResourceIndex(props) {
  const setResource = useStoreActions(
    (actions) => actions.resource.setResource,
  );

  let { path } = useRouteMatch();
  const {
    resourceName,
    resourceModel,
    columns,
    allowCreate = true,
    tableLoading = false,
  } = props;

  useEffect(() => {
    setResource({
      resourceName,
      basePath: path,
      resourceModel,
    });
  }, [resourceName, path, resourceModel, setResource]);

  return (
    <Switch>
      <PrivateRoute exact path={`${path}`}>
        <PageHeader
          className="site-page-header"
          title={resourceName}
          extra={[
            allowCreate && (
              <NewButton key={'new-button'} title={`New ${resourceName}`} />
            ),
          ]}></PageHeader>
        <PageContent>
          <ResourceTable columns={columns} tableLoading={tableLoading} />
        </PageContent>
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/new`}>
        <NewPage {...props} />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/:id/edit`}>
        <EditPage {...props} />
      </PrivateRoute>
      <PrivateRoute component={NoMatch} />
    </Switch>
  );
}
