import React from 'react';

import './styles/index.scss';
import AppRoutes from './routes';
import { createStore, StoreProvider } from 'config/store';

function App() {
  const store = createStore();
  return (
    <StoreProvider store={store}>
      <AppRoutes />
    </StoreProvider>
  );
}

export default App;
