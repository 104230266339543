import React, { useEffect, useState } from 'react';
import { Table, Button, Space, Row, Input, Col } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';

import './style.scss';
import locales from 'locales';

import ToggleActivationButton from '../ToogleActivationButton';
import DeleteButton from '../DeleteButton';
import EditButton from '../EditButton';

export default function ResourceTable(props) {
  const [ratioValue, setRatioValue] = useState(null);
  const refreshMerchant = useStoreActions(
    (actions) => actions.resource.refreshMerchant,
  );
  const setAllUserRatio = useStoreActions(
    (actions) => actions.resource.setAllUserRatio,
  );
  const changeParams = useStoreActions(
    (actions) => actions.resource.changeParams,
  );
  const resetParams = useStoreActions(
    (actions) => actions.resource.resetParams,
  );
  const searchParams = useStoreActions(
    (actions) => actions.resource.searchParams,
  );

  const listing = useStoreState((state) => state.resource.listing);
  const isLoading = useStoreState((state) => state.resource.isLoading);
  const resourceModel = useStoreState((state) => state.resource.resourceModel);
  const basePath = useStoreState((state) => state.resource.basePath);

  const { records, ratio_user, pagination } = listing;

  useEffect(() => {
    resourceModel && resetParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceModel]);

  const columns = [
    ...props.columns,
    {
      title: locales.action,
      key: 'action',
      width: 320,
      render: (text, record) => (
        <Space size="middle" style={{ width: '100%' }}>
          <EditButton url={`${basePath}/${record.id}/edit`} />
          <ToggleActivationButton {...record} onDone={() => resetParams()} />
          <DeleteButton
            allowDelete={text.is_press_release}
            id={record.id}
            onDone={() => resetParams()}
          />
        </Space>
      ),
    },
  ];

  function onSearch(value) {
    searchParams(value);
  }

  function onChangeRatio(e) {
    const isInteger = /^[0-9]$|^[1-9][0-9]$|^(100)$/;
    if (e.target.value === '' || isInteger.test(e.target.value)) {
      setRatioValue(e.target.value);
    }
  }

  function onApplyAll(value) {
    setAllUserRatio(value);
  }

  const handleTableChange = React.useCallback(
    (pagination, filters, sorter) => {
      const { affiliate_types, affiliate_provider, category } = filters;

      const types = (affiliate_types || []).join(',');
      const cate = (category || []).join(',');
      const providers = (affiliate_provider || []).join(',');
      console.log('Lucifer ~ 😈 - ResourceTable - providers', providers);

      changeParams({
        params: {
          types,
          providers,
          category: cate,
          order_by: sorter.columnKey,
          order: sorter.order,
          page: pagination.current,
          per_page: pagination.pageSize,
        },
      });
    },
    [changeParams],
  );

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <Row justify="space-between" style={{ marginBottom: 15, marginTop: 15 }}>
        <Row
          justify="start"
          align="middle"
          style={{ flex: 1 }}
          gutter={[20, 12]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}>
            <Input.Search
              disabled={isLoading}
              placeholder="Search merchant"
              allowClear
              enterButton
              style={{ width: '100%' }}
              onSearch={onSearch}
            />
          </Col>

          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}>
            <Input.Search
              disabled={isLoading}
              placeholder="Set ratio for all users"
              enterButton="Apply all"
              value={ratioValue}
              style={{ width: '100%' }}
              onSearch={onApplyAll}
              onChange={onChangeRatio}
            />
          </Col>
          {!isLoading && (
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}>
              <p
                style={{
                  marginBottom: 0,
                }}>{`Current Clever Ratio: ${100 - ratio_user}%`}</p>
            </Col>
          )}
        </Row>

        <Col style={{ marginLeft: 20 }}>
          <Button disabled={isLoading} type="primary" onClick={refreshMerchant}>
            Refresh Merchant
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        bordered
        rowKey={(record) => record.id}
        dataSource={records}
        pagination={pagination}
        loading={isLoading || props.tableLoading}
        onChange={handleTableChange}
        rowClassName="table-row-wrapper"
        scroll={{ x: (columns.length - 1) * 270 + 320, y: 500 }}
      />
    </Space>
  );
}
