import React from 'react';
import { Popconfirm, Button, Tooltip } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import locales from 'locales';
import { useHistory } from 'react-router-dom';
import { clearAuthToken } from 'utils/auth.util';

export default function SignOutButton() {
  const history = useHistory();
  const onSignOutClick = React.useCallback(() => {
    clearAuthToken();
    history.replace('/sign-in');
  }, [history]);

  return (
    <Popconfirm
      placement="topLeft"
      title={locales.signOutConfirmationMessage}
      onConfirm={onSignOutClick}
      okText={locales.yes}
      cancelText={locales.no}>
      <Tooltip placement="left" title={locales.signOut}>
        <Button shape="circle" icon={<LogoutOutlined />} />
      </Tooltip>
    </Popconfirm>
  );
}
