import React from 'react'
import DashboardLayout from 'layouts/Dashboard'
import Resource from 'components/Resources'
import PostForm from 'components/forms/PostForm'

export default function PostsPage() {
  return (
    <DashboardLayout>
      <Resource
        resourceModel="posts"
        basePath="/posts"
        resourceName="Post"
        formComponent={PostForm}
        columns={[
          {
            title: 'Thumbnail',
            dataIndex: 'photo_url',
            key: 'photo_url',
            render: (photo_url, record) =>
              photo_url && <img src={photo_url} width={100} alt="" />,
          },
          {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            render: (text, record) => text,
          },
          {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => (record.active ? 'Active' : 'Inactive'),
          },
          {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record) => new Date(text).toLocaleString(),
          },
        ]}
      />
    </DashboardLayout>
  )
}
