import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { Form, Input, Button, Space, Select, Skeleton } from 'antd';

import styles from './DailyInsightsForm.module.css';
import {
  showResource,
  getAllLabel,
  createResource,
  updateResource,
} from 'services/resource';

import ColorPicker from '../ColorPicker';
import TextEditor from '../TextEditor';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function DailyInsightsForm(props) {
  const { id } = props;
  const isNewForm = !id;
  const history = useHistory();
  const [formRef] = Form.useForm();

  const { basePath, resourceModel } = useStoreState((state) => state.resource);

  const [labels, setLabels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isIniting, setIsIniting] = useState(true);
  const [colorPickerState, setColorPicker] = useState({
    color: '',
    visible: false,
  });

  useEffect(() => {
    async function initForm() {
      setIsIniting(true);

      const {
        data: { records: listLabel },
      } = await getAllLabel(resourceModel);
      setLabels(() =>
        listLabel.map((label) => ({ value: `${label.id}`, label: label.name })),
      );

      if (isNewForm) {
        formRef.setFieldsValue({
          label_name: [],
          selected_name: { id: null, name: '' },
          label_icon: '',
          label_color: '',
          title: '',
          description: '',
          content: '',
        });
        setIsIniting(false);
        return;
      }
      const resp = await showResource({ resourceModel, id });
      if (resp.success) {
        const entry = resp.data.record;

        formRef.setFieldsValue({
          label_name: [entry.label.name] || [],
          selected_name: { id: entry.label.id, name: entry.label.name },
          label_icon: entry.label.icon,
          label_color: entry.label.color,
          title: entry.title,
          description: entry.description,
          content: entry.content,
        });
      } else {
      }
      setIsIniting(false);
    }
    if (resourceModel) initForm();
  }, [resourceModel, id, isNewForm, formRef]);

  const handleLabel = (values) => {
    const date = new Date().toJSON().slice(0, 10).replace(/-/g, '/');

    return {
      ...values,
      label_id: values.selected_name.id,
      label_name: values.selected_name.name,
      date,
      priority: 1,
    };
  };

  const onFinish = React.useCallback(
    async (values) => {
      const formData = handleLabel(values);
      setLoading(true);
      const resp = isNewForm
        ? await createResource({ resourceModel, ...formData, id })
        : await updateResource({ resourceModel, ...formData, id });
      setLoading(false);
      if (resp.success) {
        history.push(basePath);
      } else {
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, isNewForm, basePath, resourceModel],
  );

  const onCancel = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  const handleSelect = (value, option) => {
    if (!value || !value?.length) {
      formRef.setFieldsValue({
        label_name: [],
        selected_name: { id: null, name: '' },
      });
      return;
    }
    const lastValue = value[value.length - 1];
    const lastOption = option[option.length - 1];

    if (!!lastOption.value) {
      formRef.setFieldsValue({
        label_name: [lastValue],
        selected_name: { id: Number(lastOption.value), name: lastOption.label },
      });
    } else {
      formRef.setFieldsValue({
        label_name: [lastValue],
        selected_name: { id: null, name: lastValue },
      });
    }
  };

  const handleChangeLabelContent = useCallback(
    (html, pureContent) => {
      formRef.setFieldsValue({ content: html });
    },
    [formRef],
  );

  if (isIniting) {
    return (
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
        <Skeleton.Input style={{ width: 600 }} active />
      </Space>
    );
  }
  return (
    <>
      <Form {...layout} form={formRef} name="DailyInsight" onFinish={onFinish}>
        <Form.Item
          label="Type"
          name="label_name"
          rules={[
            {
              required: true,
              message: 'Type is required',
            },
          ]}>
          <Select
            mode="tags"
            className={styles.selectInput}
            placeholder="Select your type"
            options={labels}
            onChange={handleSelect}
          />
        </Form.Item>
        <Form.Item label="" name="selected_name" style={{ display: 'none' }}>
          <Input placeholder="Enter your icon" />
        </Form.Item>

        <Form.Item
          label="Icon"
          name="label_icon"
          rules={[
            {
              required: true,
              message: 'Icon is required',
            },
            {
              max: 10,
              message: 'Label cannot exceed 10 characters',
            },
          ]}>
          <Input placeholder="Enter your icon" />
        </Form.Item>

        <Form.Item
          label="Background color"
          name="label_color"
          rules={[
            {
              required: true,
              message: 'Background color is required',
            },
          ]}>
          <>
            <Button
              type="default"
              className={styles.backgroundColorBtn}
              onClick={() =>
                setColorPicker((prev) => ({ ...prev, visible: true }))
              }>
              {formRef.getFieldValue('label_color')?.length ? (
                <div className={styles.backgroundColorInput}>
                  <div
                    style={{
                      width: 12,
                      height: 12,
                      borderRadius: 2,
                      backgroundColor: formRef.getFieldValue('label_color'),
                      marginRight: 5,
                    }}
                  />
                  <span>{formRef.getFieldValue('label_color')}</span>
                </div>
              ) : (
                <span style={{ color: '#bfbfbf' }}>
                  Enter your background color
                </span>
              )}
            </Button>
            <ColorPicker
              visible={colorPickerState.visible}
              color={formRef.getFieldValue('label_color')}
              onChangeComplete={(color) => {
                formRef.setFieldsValue({ label_color: color });
                setColorPicker({ color, visible: false });
              }}
            />
          </>
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: 'Title is required',
            },
            {
              max: 50,
              message: 'Title cannot exceed 50 characters',
            },
          ]}>
          <Input placeholder="Enter your title" />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Description is required',
            },
            {
              max: 200,
              message: 'Description cannot exceed 200 characters',
            },
          ]}>
          <Input.TextArea placeholder="Enter your description" rows={5} />
        </Form.Item>

        <Form.Item
          label="Content"
          name="content"
          style={{ minHeight: 120 }}
          rules={[
            {
              required: true,
              message: 'Content is required',
            },
          ]}>
          <TextEditor
            placeholder="Enter your content"
            handleChangeLabelContent={handleChangeLabelContent}
            value={formRef.getFieldValue('content')}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" disabled={loading}>
              Save
            </Button>
            <Button type="default" onClick={onCancel}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}

export default DailyInsightsForm;
